/* eslint-disable no-unused-expressions */
import { ExclamationCircleFilled } from '@ant-design/icons';
import type { DetailItem } from '@iib/pandora-box';
import { DetailLayout, ShouldRender, confirm, useTranslation, MapMTK } from '@iib/pandora-box';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { Rating } from '../../../types';
import { getEnv, MAP_STYLE, ratingCategories, ucFirst } from '../../../utils';
import { useApproveRating, useDeleteRating, useGetRating } from '../hooks';
import * as S from './styles';

type P = Record<string, any>;

type DetailItemsType = {
  [key in keyof Partial<P>]: DetailItem<P, key>;
};

export const RatingDetail = (): ReactElement => {
  const {
    location: { pathname },
    push,
  } = useHistory();
  const id = pathname.split('/')[4];
  const [mappedRating, setMappedRating] = useState<P>();
  const [rating, setRating] = useState<Rating>();
  const [fields, setFields] = useState<DetailItemsType>();
  const { t } = useTranslation();
  const { performAction: getRating, loading: loadingRating } = useGetRating();
  const { performAction: deleteRating } = useDeleteRating();
  const { performAction: approveRating } = useApproveRating();

  const showDeleteConfirm = () => {
    confirm({
      title: t('delete_rating_heading'),
      icon: <ExclamationCircleFilled />,
      okType: 'danger',
      cancelText: t('cancel'),
      async onOk() {
        const response = await deleteRating({ id: String(id) });
        if (!!response && 'affected' in response && response.affected === 1) push('/residentialArea/ratings');
      },
    });
  };

  const showApproveConfirm = () => {
    confirm({
      title: t('approve_rating_heading'),
      icon: <ExclamationCircleFilled />,
      cancelText: t('cancel'),
      async onOk() {
        const response = await approveRating({ id: String(id) });
        if (!!response && 'affected' in response && response.affected === 1) push('/residentialArea/ratings');
      },
    });
  };

  const fetchRating = async () => {
    const fetchedRating = await getRating({ id });
    if (fetchedRating) {
      setRating(fetchedRating);
      const tempObject: P = fetchedRating;
      tempObject.map = [fetchedRating?.lng, fetchedRating?.lat];
      // eslint-disable-next-line no-restricted-syntax
      setMappedRating(tempObject);
    }
  };

  useEffect(() => {
    fetchRating();
  }, [id]);

  useEffect(() => {
    if (!mappedRating || !rating) return;

    let order = 1;
    const fieldsObject: typeof fields = {
      wohnlagenkarte: {
        label: t('to_wohnlagenkarte'),
        order: 98,
        render: () => (
          <a href={`${getEnv('REACT_APP_WOHNLAGENKARTE_URL')}/${rating.lat}/${rating.lng}/18`} target="_blank" rel="noopener noreferrer">
            Lat: {rating.lat}, Long: {rating.lng}
          </a>
        ),
      },
      map: {
        label: 'Map',
        order: 99,
        render: (value) => (
          <S.MapWrapper>
            <MapMTK mapId={'ratingDetailMap'} mapType={MAP_STYLE} initialViewState={{ center: value, zoom: 13, pitch: 0, bearing: 0 }} addMarkerAt={[value[0], value[1]]} />
          </S.MapWrapper>
        ),
      },

      ...(rating.isOnSiteRating
        ? {
            onSiteScore: {
              label: t('on_site_score'),
              order: 1,
            },

            createdAt: {
              label: t('created_at'),
              order: 2,
              render: (createdAt) => <>{`${moment(createdAt).format('DD.MM.YYYY hh:mm')}${t('hour')}`}</>,
            },

            userEmail: {
              label: t('email_c'),
              order: 3,
              render: (email) => email || 'N/A',
            },
          }
        : {}),
    };

    if (!rating.isOnSiteRating) {
      ratingCategories.forEach((category) =>
        Object.assign(
          fieldsObject,
          ...category.ratingAttributes.map((attr) => ({
            [`a${ucFirst(attr.id)}`]: {
              label: t(attr.title),
              order: order++,
              render: () => (
                <>
                  <ShouldRender if={(rating as any)[`a${ucFirst(attr.id)}`]}>
                    <ShouldRender if={attr.type === 'select' || attr.type === 'multiple'}>{(rating as any)[`a${ucFirst(attr.id)}`]}</ShouldRender>
                    <ShouldRender if={attr.type !== 'select' && attr.type !== 'multiple'}>
                      {attr.options
                        .filter((o) => o.points === (rating as any)[`a${ucFirst(attr.id)}`])
                        .map((o) => o.title)
                        .join(', ')}
                    </ShouldRender>
                  </ShouldRender>
                  <ShouldRender if={!(rating as any)[`a${ucFirst(attr.id)}`]}>N/A</ShouldRender>
                </>
              ),
            },
          }))
        )
      );
    }

    setFields(fieldsObject);
  }, [mappedRating, t]);

  return (
    <>
      {mappedRating && (
        <>
          <DetailLayout
            width="100%"
            columns={1}
            loading={loadingRating}
            Loader={<Loading />}
            header={{
              title: t('rating'),
              ...(!rating?.isOnSiteRating && {
                showDelete: true,
                onDelete: () => showDeleteConfirm(),
                rightButton: {
                  text: t('approve_rating'),
                  onClick: () => showApproveConfirm(),
                },
              }),
            }}
            record={mappedRating}
            fields={fields || {}}
          />
        </>
      )}
    </>
  );
};
